import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
import JwtPayloadViewer from '../../../components/JwtPayloadViewer';
export const _frontmatter = {
  "product": "verify",
  "category": "eIDs",
  "sort": 9,
  "title": "Dutch iDIN",
  "subtitle": "Learn more about Dutch iDIN token contents available in the four different user profiles."
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Highlight = makeShortcode("Highlight");
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`JWT/Token examples`}</h2>
    <p>{`The Dutch iDIN is provided by the Dutch banks through the Dutch Payments Association. The user experience will vary depending on the type of authentication requested.`}</p>
    <p>{`Four different authentication options are available:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/verify/e-ids/dutch-idin/#full-identification"
        }}>{`Full identification`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/verify/e-ids/dutch-idin/#re-identification"
        }}>{`Re-identification`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/verify/e-ids/dutch-idin/#safe-login"
        }}>{`Safe login`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/verify/e-ids/dutch-idin/#age-verification"
        }}>{`Age verification`}</a></li>
    </ul>
    <p>{`Different options will render user profiles with different JWT claims.`}</p>
    <p>{`Developers can specify the user profile by providing the required value in the `}<inlineCode parentName="p">{`scope`}</inlineCode>{` parameter. Alternatively, the value can be supplied in the `}<inlineCode parentName="p">{`login_hint`}</inlineCode>{`.`}</p>
    <Highlight icon="info" mdxType="Highlight">
      <p>{`The level of assurance for iDIN (all options) is: Substantial`}</p>
    </Highlight>
    <h3>{`Full identification`}</h3>
    <p>{`Use `}<inlineCode parentName="p">{`scope=idin:full-id`}</inlineCode>{`, or add `}<inlineCode parentName="p">{`login_hint=scope:idin:full-id`}</inlineCode>{` to the authorize request to get this profile:`}</p>
    <JwtPayloadViewer payload={{
      iss: 'https://localhost:44362',
      aud: 'https://localhost:44301/',
      nonce: 'ecnon',
      identityscheme: 'nlidin',
      authenticationtype: 'urn:grn:authn:nl:idin',
      'http://schemas.microsoft.com/ws/2008/06/identity/claims/authenticationmethod': 'urn:grn:authn:nl:idin',
      'http://schemas.microsoft.com/ws/2008/06/identity/claims/authenticationinstant': '2023-10-11T19:32:59.952Z',
      'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier': '7d704c6d4d1245a0ba8d135d18f9d518',
      sub: '{7d704c6d-4d12-45a0-ba8d-135d18f9d518}',
      'http://schemas.grean.id/claims/sessionindex': '16494935-5ffc-4ae6-a683-6587a27b35c4',
      'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country': 'NL',
      email: 'info@equensworldline.nl',
      phone_number: '+31203051900',
      'http://schemas.grean.id/claims/gender': 'MALE',
      'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname': 'Vries',
      family_name: 'Vries',
      'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/dateofbirth': '1975-07-25',
      birthdate: '1975-07-25',
      verified_claims: {
        bin: 'FANTASYBANK1234567890',
        transientid: null,
        legallastname: 'Vries',
        preferredlastname: 'Vries-Jansen',
        partnerlastname: 'Jansen',
        legallastnameprefix: 'de',
        preferredlastnameprefix: 'de',
        partnerlastnameprefix: 'de',
        initials: 'VJ',
        dateofbirth: '1975-07-25',
        ageCheck: null,
        street: 'Pascalstreet',
        houseno: '19',
        housenosuf: null,
        addressextra: null,
        postalcode: '0000AA',
        city: 'Aachen',
        intaddressline1: null,
        intaddressline2: null,
        intaddressline3: null,
        country: 'DE',
        phone1: '+31203051900',
        phone2: null,
        phone3: null
      },
      iat: 1697052782,
      nbf: 1697052782,
      exp: 1697055314
    }} mdxType="JwtPayloadViewer" />
    <h3>{`Re-identification`}</h3>
    <p>{`Use `}<inlineCode parentName="p">{`scope=idin:re-id`}</inlineCode>{`, or add `}<inlineCode parentName="p">{`login_hint=scope:idin:re-id`}</inlineCode>{` to the authorize request to get this profile:`}</p>
    <JwtPayloadViewer payload={{
      iss: 'https://localhost:44362',
      aud: 'https://localhost:44301/',
      nonce: 'ecnon',
      identityscheme: 'nlidin',
      authenticationtype: 'urn:grn:authn:nl:idin',
      'http://schemas.microsoft.com/ws/2008/06/identity/claims/authenticationmethod': 'urn:grn:authn:nl:idin',
      'http://schemas.microsoft.com/ws/2008/06/identity/claims/authenticationinstant': '2023-10-11T19:33:06.499Z',
      'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier': '7d704c6d4d1245a0ba8d135d18f9d518',
      sub: '{7d704c6d-4d12-45a0-ba8d-135d18f9d518}',
      'http://schemas.grean.id/claims/sessionindex': '4c6fc8ad-9ddd-4176-ad2f-0d8a11ba9b88',
      'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country': 'NL',
      verified_claims: {
        bin: 'FANTASYBANK1234567890'
      },
      iat: 1697052787,
      nbf: 1697052787,
      exp: 1697055319
    }} mdxType="JwtPayloadViewer" />
    <h3>{`Safe login:`}</h3>
    <p>{`Use `}<inlineCode parentName="p">{`scope=idin:safe-login`}</inlineCode>{`, or add `}<inlineCode parentName="p">{`login_hint=scope:idin:safe-login`}</inlineCode>{` to the authorize request to get this profile:`}</p>
    <JwtPayloadViewer payload={{
      iss: 'https://localhost:44362',
      aud: 'https://localhost:44301/',
      nonce: 'ecnon',
      identityscheme: 'nlidin',
      authenticationtype: 'urn:grn:authn:nl:idin',
      'http://schemas.microsoft.com/ws/2008/06/identity/claims/authenticationmethod': 'urn:grn:authn:nl:idin',
      'http://schemas.microsoft.com/ws/2008/06/identity/claims/authenticationinstant': '2023-10-11T19:33:10.642Z',
      'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier': '7d704c6d4d1245a0ba8d135d18f9d518',
      sub: '{7d704c6d-4d12-45a0-ba8d-135d18f9d518}',
      'http://schemas.grean.id/claims/sessionindex': '9c141c6f-1c7c-4a4c-b981-c2a5eb3e54a0',
      'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country': 'NL',
      verified_claims: {
        bin: 'FANTASYBANK1234567890'
      },
      iat: 1697052791,
      nbf: 1697052791,
      exp: 1697055323
    }} mdxType="JwtPayloadViewer" />
    <p>{`Re-identification and Safe login return the same user data. However, the UI texts are slightly different, and the cost per `}<inlineCode parentName="p">{`re-id`}</inlineCode>{` transaction is higher than per `}<inlineCode parentName="p">{`safe-login`}</inlineCode>{` transaction.`}</p>
    <h3>{`Age verification`}</h3>
    <p>{`The default age verification is set to 18+.`}<br parentName="p"></br>{`
`}{`Use `}<inlineCode parentName="p">{`scope=idin:age-verification`}</inlineCode>{`, or add `}<inlineCode parentName="p">{`login_hint=scope:idin:age-verification`}</inlineCode>{` to the authorize request to get this profile:`}</p>
    <JwtPayloadViewer payload={{
      iss: 'https://localhost:44362',
      aud: 'https://localhost:44301/',
      nonce: 'ecnon',
      identityscheme: 'nlidin',
      authenticationtype: 'urn:grn:authn:nl:idin',
      'http://schemas.microsoft.com/ws/2008/06/identity/claims/authenticationmethod': 'urn:grn:authn:nl:idin',
      'http://schemas.microsoft.com/ws/2008/06/identity/claims/authenticationinstant': '2023-10-11T19:33:14.828Z',
      'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier': '7d704c6d4d1245a0ba8d135d18f9d518',
      sub: '{7d704c6d-4d12-45a0-ba8d-135d18f9d518}',
      'http://schemas.grean.id/claims/sessionindex': 'fccdab0b-689d-4836-8bb1-348aac5082bf',
      'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country': 'NL',
      verified_claims: {
        bin: 'FANTASYBANK1234567890',
        ageCheck: {
          ageOrOlder: 18,
          ageCheckPassed: true
        }
      },
      iat: 1697052795,
      nbf: 1697052795,
      exp: 1697055327
    }} mdxType="JwtPayloadViewer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      